import classNames from 'classnames';
import React, { Fragment, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  BellIcon, ChevronRightIcon, CogIcon, CollectionIcon, HomeIcon, MenuAlt2Icon, PhotographIcon,
  PlusIcon, ScaleIcon, UserGroupIcon, ViewGridIcon, XIcon
} from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';

import config from '../../config';
import logo from '../../images/icons/logo.svg';
import {
  KnockNotificationFeed, NotificationFeedPopover, NotificationIconButton
} from '../../services/knock';
import ClaimLookup from './ClaimLookup';

const FALLBACK_PROFILE_PHOTO =
  'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80';

interface LayoutProps {
  navigation: { href?: string; name: string; current?: boolean }[];
}

const Layout: React.FC<LayoutProps> = ({ navigation, children }) => {
  const location = useLocation();
  const history = useHistory();
  const { user, isLoading, logout } = useAuth0();

  const [searchInput, setSearchInput] = useState('');

  const [notificationsFeedIsVisible, setNotificationsFeedIsVisible] =
    useState(false);
  const notificationsButtonRef = useRef<HTMLButtonElement>(null);

  const sidebarNavigation = (
    config.useIntakeOnlyMode
      ? [{ name: 'Intake', href: '/claims', icon: HomeIcon }]
      : config.useInvestigationMode
      ? [{ name: 'Investigation', href: '/cases', icon: ScaleIcon }]
      : [
          { name: 'Intake', href: '/claims', icon: HomeIcon },
          { name: 'CAT', href: '/cat/dashboard', icon: ViewGridIcon },
          { name: 'Plugins', href: '/plugins', icon: PhotographIcon },
        ]
  )
    .concat(
      !config.hideUserManagement
        ? [{ name: 'Users', href: '/users', icon: UserGroupIcon }]
        : [],
    )
    .concat([{ name: 'Settings', href: '/settings', icon: CogIcon }]);

  return (
    <div className="h-screen bg-gray-50 flex overflow-hidden">
      {/* Narrow sidebar */}
      <div className="w-28 bg-gray-900 overflow-y-auto">
        <div className="w-full py-6 flex flex-col items-center">
          <div className="flex-shrink-0 flex items-center">
            <img className="h-8 w-auto" src={logo} alt="Workflow" />
          </div>
          <div className="flex-1 mt-6 w-full px-2 space-y-1">
            {sidebarNavigation
              .map(item => {
                return {
                  ...item,
                  current: location.pathname.startsWith(item.href),
                };
              })
              .map(item => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-gray-800 text-white'
                      : 'text-indigo-100 hover:bg-gray-800 hover:text-white',
                    'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium',
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? 'text-white'
                        : 'text-gray-300 group-hover:text-white',
                      'h-6 w-6',
                    )}
                    aria-hidden="true"
                  />
                  <span className="mt-2">{item.name}</span>
                </Link>
              ))}
          </div>
        </div>
      </div>

      {/* Content area */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="w-full">
          <div className="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
            <div className="flex-1 flex justify-between px-4 sm:px-6">
              <div className="flex-1 flex">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="flex items-center space-x-4">
                    <li>
                      <div>
                        <Link
                          to="/"
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <HomeIcon
                            className="flex-shrink-0 h-5 w-5"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Home</span>
                        </Link>
                      </div>
                    </li>
                    {navigation.map(page => (
                      <li key={page.name}>
                        <div className="flex items-center">
                          <ChevronRightIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <Link
                            to={page.href || '#'}
                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                            aria-current={page.current ? 'page' : undefined}
                          >
                            {page.name}
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ol>
                </nav>
              </div>
              <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
                <div className="ml-10 pr-4 flex-shrink-0 flex items-center space-x-10">
                  {location.pathname.includes('/cat') ? (
                    <span className="flex space-x-6">
                      <Link
                        to="/cat/configurator"
                        className="-relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      >
                        <CogIcon className="mr-2 h-4 w-4" />
                        Configurator
                      </Link>
                      <Link
                        to="/cat/management"
                        className="-relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                      >
                        <CollectionIcon className="mr-2 h-4 w-4" />
                        Management
                      </Link>
                    </span>
                  ) : config.useInvestigationMode || config.usePlatformMode ? (
                    <>
                      <ClaimLookup
                        mode={config.useInvestigationMode ? 'case' : 'claim'}
                      />
                    </>
                  ) : (
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                      }}
                    >
                      <div className="flex rounded-md shadow-sm">
                        <div className="relative flex-grow focus-within:z-10">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <svg
                              className="h-5 w-5 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          <input
                            className="form-input w-36 rounded-none rounded-l-md pl-10 transition ease-in-out duration-150 sm:block sm:text-sm sm:leading-5"
                            placeholder="Claim ID"
                            value={searchInput}
                            onChange={e => setSearchInput(e.target.value)}
                          />
                        </div>
                        <button
                          type="submit"
                          className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                        >
                          Lookup
                        </button>
                      </div>
                    </form>
                  )}

                  {/* <nav aria-label="Global" className="flex space-x-10">
                    <a href="#" className="text-sm font-medium text-gray-900">
                      Settings
                    </a>
                  </nav> */}

                  <div className="flex items-center space-x-8">
                    {config.notifications ? (
                      <KnockNotificationFeed
                        displayMode="custom"
                        customRenderer={({
                          onNotificationClick,
                          renderItem,
                        }) => (
                          <div>
                            <span className="text-gray-400 hover:text-gray-500">
                              <NotificationIconButton
                                ref={notificationsButtonRef}
                                onClick={e =>
                                  setNotificationsFeedIsVisible(
                                    !notificationsFeedIsVisible,
                                  )
                                }
                              />
                            </span>
                            <NotificationFeedPopover
                              buttonRef={notificationsButtonRef}
                              isVisible={notificationsFeedIsVisible}
                              onClose={() =>
                                setNotificationsFeedIsVisible(false)
                              }
                              onNotificationClick={onNotificationClick}
                              renderItem={renderItem}
                            />
                          </div>
                        )}
                      />
                    ) : null}

                    <Menu as="div" className="relative inline-block text-left">
                      {({ open }) => (
                        <>
                          <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src={
                                config.authentication
                                  ? user?.picture
                                  : FALLBACK_PROFILE_PHOTO
                              }
                              alt=""
                            />
                          </Menu.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-right absolute z-30 right-0 mt-2 w-72 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none shadow overflow-hidden rounded"
                            >
                              <div className="py-1">
                                <div className="py-2 px-4">
                                  <div className="font-bold text-sm text-gray-800">
                                    {user?.name}
                                  </div>
                                  <div className="text-sm text-gray-600 break-words">
                                    {user?.email}
                                  </div>
                                  <div className="text-xs text-gray-400 mt-1">
                                    {user?.sub}
                                  </div>
                                </div>
                                {/* <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 font-medium cursor-pointer',
                                      )}
                                    >
                                      Your settings
                                    </a>
                                  )}
                                </Menu.Item> */}
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      onClick={() => logout()}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 font-base cursor-pointer',
                                      )}
                                    >
                                      Log out
                                    </a>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* Main content */}
        <div className="flex-1 flex items-stretch overflow-hidden">
          <main className="flex-1 overflow-y-auto">{children}</main>
          {/* <aside className="hidden w-96 bg-white border-l border-gray-200 overflow-y-auto lg:block">
          </aside> */}
        </div>
      </div>
    </div>
  );
};

export default Layout;
