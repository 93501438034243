import React, { useContext, useState } from 'react';

interface IParticipant {
  id: string;
}
interface IMessage {
  id: string;
  from: string;
  message: string;
  date: Date;
}
export interface IChat {
  id: string;
  participants: IParticipant[];
  messages: IMessage[];
}
interface IMessageState {
  composerVisible: boolean;
  chats: { [id: string]: IChat };
}
interface IMessageContext {
  state: IMessageState;
  setState: React.Dispatch<React.SetStateAction<IMessageState>>;
}

export const contacts = [
  {
    id: '1',
    name: 'Jane Doe',
    role: 'Filing Party Claimant',
    initials: 'JD',
    backgroundColor: 'bg-blue-400',
  },
  {
    id: '2',
    name: 'Lucas Doe',
    role: 'Passenger in Filing Vehicle',
    initials: 'LD',
    backgroundColor: 'bg-orange-400',
  },
  {
    id: '3',
    name: 'Carmel Auto Repair',
    role: 'Body Shop',
    initials: 'CAR',
    backgroundColor: 'bg-pink-400',
  },
];

const MessagingState: IMessageState = {
  composerVisible: true,
  chats: {
    sample: {
      id: 'sample',
      participants: [{ id: '1' }],
      messages: [
        {
          id: 'xxx',
          from: 'self',
          message:
            'Hey, this is your adjuster, Claire. Letting you know we got your case and are checking it out right now!',
          date: new Date('2021-08-06T04:14'),
        },
        {
          id: 'aaa',
          from: '1',
          message: 'Thank u so much!',
          date: new Date('2021-08-06T04:15'),
        },
        {
          id: 'xxx',
          from: 'self',
          message: "No prob lol it's my job",
          date: new Date('2021-08-06T04:16'),
        },
        {
          id: 'aaa',
          from: '1',
          message: 'I know lol',
          date: new Date('2021-08-06T04:18'),
        },
        {
          id: 'xxx',
          from: 'self',
          message: 'K thx bye',
          date: new Date('2021-08-06T04:21'),
        },
      ],
    },
  },
};

const MessagingContext = React.createContext({
  state: MessagingState,
  setState: (() => {}) as React.Dispatch<React.SetStateAction<IMessageState>>,
});

export const MessagingProvider: React.FC<{}> = ({ children }) => {
  const [messagingState, setMessagingState] = useState(MessagingState);
  return (
    <MessagingContext.Provider
      value={{ state: messagingState, setState: setMessagingState }}
    >
      {children}
    </MessagingContext.Provider>
  );
};

export function useMessaging() {
  const ctx = useContext<IMessageContext>(MessagingContext);
  const { state, setState } = ctx;

  return {
    showComposer: () => {
      setState(s => ({ ...s, composerVisible: true }));
    },
    hideComposer: () => {
      setState(s => ({ ...s, composerVisible: false }));
    },
    composerVisible: state.composerVisible,
    chats: state.chats,
    sendMessage: ({
      chat,
      message,
      recipients,
    }: {
      chat: string;
      message: string;
      recipients?: string[];
    }) => {
      simulateSend(ctx, { chat, message, recipients });
    },
  };
}

function simulateSend(
  ctx: IMessageContext,
  {
    chat,
    message,
    recipients,
  }: { chat: string; message: string; recipients?: string[] },
) {
  if (chat === 'new' && recipients) {
    ctx.setState(s => {
      const id = Math.random().toString();
      s.chats[id] = {
        id,
        participants: recipients.map(id => ({ id })),
        messages: [
          {
            id: Math.random().toString(),
            from: 'self',
            message,
            date: new Date(),
          },
        ],
      };
      return s;
    });
  } else {
    ctx.setState(s => {
      s.chats[chat].messages = s.chats[chat].messages.concat([
        {
          id: Math.random().toString(),
          from: 'self',
          message,
          date: new Date(),
        },
      ]);
      return s;
    });
  }
}
