import './App.css';
import 'react-image-lightbox/style.css';

import { createBrowserHistory } from 'history';
import React, { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Link, Redirect, Route, RouteComponentProps, RouteProps, Router, useRouteMatch
} from 'react-router-dom';

import { Auth0Provider, useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import PageLoader from '@frontend/components/PageLoader';
import { ArrowRightIcon } from '@heroicons/react/outline';

import MessagingContainer from './components/Messaging/MessagingContainer';
import Header from './components/shared/Header';
import Layout from './components/shared/Layout';
import Nav from './components/shared/Nav';
import config from './config';
import { MessagingProvider } from './hooks/useMessaging';
import { AuthenticatedApolloProvider } from './services/apollo';

const Welcome = React.lazy(() => import('./components/Welcome'));
const UserManagement = React.lazy(() => import('./components/UserManagement'));
const Settings = React.lazy(() => import('./components/Settings'));

const ClaimDashboard = React.lazy(() => import('./components/ClaimDashboard'));
const ClaimView = React.lazy(() => import('./components/ClaimView'));

const CaseDashboard = React.lazy(() => import('./components/CaseDashboard'));
const CaseView = React.lazy(() => import('./components/CaseView'));

const PluginDashboard = React.lazy(
  () => import('./components/PluginDashboard'),
);

const CatMap = React.lazy(() => import('./components/Cat/CatMap'));
const CatFlow = React.lazy(() => import('./components/Cat/CatFlow'));
const CatManagement = React.lazy(
  () => import('./components/Cat/CatManagement'),
);

const history = createBrowserHistory();

const Placeholder: React.FC<{ message: JSX.Element | string }> = ({
  message,
}) => (
  <div className="absolute inset-0 flex items-center justify-center">
    <div className="font-bold text-gray-500 max-w-xl p-4 text-center">
      {message}
    </div>
  </div>
);

const ProtectedRoute = ({ component, ...args }: RouteProps) =>
  component ? (
    config.authentication ? (
      <Route
        component={withAuthenticationRequired(
          component as any, // due to auth0 typings not being compatible with react-router-dom
          {
            onRedirecting: () => (
              <Placeholder message="Authorizing access to Assured ClaimView..." />
            ),
          },
        )}
        {...args}
      />
    ) : (
      <Route component={component} {...args} />
    )
  ) : null;

const onRedirectCallback = (appState: any) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

function Main() {
  const { error, logout, user } = useAuth0();

  useEffect(() => {
    const Intercom = (window as any).Intercom;
    if (config.enableIntercom && user) {
      Intercom('boot', {
        app_id: 's49bfo9k',
        user_id: user.sub,
        name: user.name,
        email: user.email,
        company: {
          id: config.tenant,
          name: config.tenant,
        },
      });

      const unregister = history.listen(() => {
        Intercom('update');
      });

      return () => unregister();
    }
  }, [user]);

  if (error) {
    return (
      <Placeholder
        message={
          <div>
            <div>
              {(error as any)?.error_description ||
                'An unknown authentication error occurred. Please try again.'}
            </div>
            <div className="mt-1">
              <button
                className="cursor-pointer text-blue-700 hover:text-blue-600 text-xs font-bold"
                onClick={() => logout()}
              >
                Log out
              </button>
            </div>
          </div>
        }
      />
    );
  }

  return (
    <AuthenticatedApolloProvider>
      <MessagingProvider>
        <Router history={history}>
          <Helmet>
            <title>Assured ClaimView</title>
          </Helmet>
          <div className="App bg-gray-100">
            <Suspense
              fallback={
                config.usePlatformMode ? (
                  <Layout navigation={[]}>
                    <PageLoader
                      style={{ minHeight: '80rvh' }}
                      text="Loading Assured..."
                    />
                  </Layout>
                ) : (
                  <>
                    <Header />
                    <PageLoader
                      style={{ minHeight: '80rvh' }}
                      text="Retrieving Assured ClaimView..."
                    />
                  </>
                )
              }
            >
              {!config.whitelabel ? <Nav /> : null}
              {/* Dashboard/Landing Page */}
              {config.useInvestigationMode ? (
                <>
                  <ProtectedRoute exact path="/" component={Welcome} />
                  <ProtectedRoute
                    exact
                    path="/cases"
                    component={CaseDashboard}
                  />
                </>
              ) : config.usePlatformMode ? (
                <>
                  <ProtectedRoute exact path="/" component={Welcome} />
                  <ProtectedRoute
                    exact
                    path="/claims"
                    component={ClaimDashboard}
                  />
                </>
              ) : (
                <>
                  <ProtectedRoute exact path="/" component={Welcome} />
                  <Route exact path="/claims">
                    <Redirect to="/" />
                  </Route>
                </>
              )}

              <ProtectedRoute path="/plugins" component={PluginDashboard} />

              <ProtectedRoute path="/users" component={UserManagement} />
              <ProtectedRoute path="/settings" component={Settings} />

              <Route
                exact
                path="/claims/:id"
                render={props => (
                  <Redirect to={`/claims/${props.match.params.id}/general`} />
                )}
              />
              <ProtectedRoute path="/claims/:id/:view?" component={ClaimView} />
              <Route
                path="/claim/"
                render={props => (
                  <Redirect
                    to={`/claims/${props.location.pathname.substring(
                      '/claim/'.length,
                    )}${props.location.search}`}
                  />
                )}
              />

              <ProtectedRoute path="/cases/:id" component={CaseView} />

              {/* CAT Administrator */}
              <Route exact path="/cat">
                <Redirect to="/cat/dashboard" />
              </Route>
              <Route path="/cat/dashboard">
                <Layout navigation={[{ current: true, name: 'CAT' }]}>
                  <CatMap />
                </Layout>
              </Route>
              <ProtectedRoute
                path="/cat/management"
                component={CatManagement}
              />
              <Route path="/cat/configurator">
                <Layout
                  navigation={[
                    { href: '/cat', name: 'CAT' },
                    { current: true, name: 'Configurator' },
                  ]}
                >
                  <CatFlow />
                </Layout>
              </Route>
            </Suspense>
            {config.enableMessaging ? <MessagingContainer /> : null}
          </div>
        </Router>
      </MessagingProvider>
    </AuthenticatedApolloProvider>
  );
}

function App() {
  if (config.authentication) {
    return (
      <Auth0Provider
        domain={config.authentication.domain}
        clientId={config.authentication.clientId}
        audience={config.authentication.audience}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <Main />
      </Auth0Provider>
    );
  } else {
    return <Main />;
  }
}

export default App;
