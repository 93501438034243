import 'emoji-mart/css/emoji-mart.css';

import classNames from 'classnames';
import { Picker } from 'emoji-mart';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Mention, MentionsInput, MentionsInputProps } from 'react-mentions';

import { EmojiHappyIcon, LightningBoltIcon, PlusIcon, XIcon } from '@heroicons/react/outline';

import { IChat, useMessaging } from '../../hooks/useMessaging';
import { MessagingMessage, MessagingParticipant } from './types';

const contacts = [
  {
    id: '1',
    name: 'Jane Doe',
    role: 'Filing Party Claimant',
    initials: 'JD',
    backgroundColor: 'bg-blue-400',
  },
  {
    id: '2',
    name: 'Lucas Doe',
    role: 'Passenger in Filing Vehicle',
    initials: 'LD',
    backgroundColor: 'bg-orange-400',
  },
  {
    id: '3',
    name: 'Carmel Auto Repair',
    role: 'Body Shop',
    initials: 'CAR',
    backgroundColor: 'bg-pink-400',
  },
];

interface MessagingPopupProps {
  chat?: IChat;
  onClose: () => void;
}

const MessagingPopup: React.FC<MessagingPopupProps> = ({ chat, onClose }) => {
  const { participants, messages } =
    chat || ({ id: '', participants: [], messages: [] } as IChat);
  const { sendMessage } = useMessaging();
  const [collapsed, setCollapsed] = useState(false);
  const [draftMessage, setDraftMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isNewMessage = participants.length === 0;
  const [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);
  const [recipientFilterText, setRecipientFilterText] = useState('');

  const onSubmit = () => {
    if (isNewMessage) {
      sendMessage({
        chat: 'new',
        message: draftMessage,
        recipients: selectedRecipients,
      });
      onClose();
    } else if (chat) {
      sendMessage({
        chat: chat.id,
        message: draftMessage,
      });
      setDraftMessage('');
    }
  };

  return (
    <div
      className="bg-white rounded-t-md shadow flex flex-col border-t border-l border-r border-gray-200 z-50 pointer-events-auto"
      style={{
        width: 300,
        ...(collapsed ? {} : { height: '60vh', maxHeight: 400 }),
      }}
    >
      <div
        className="flex items-center text-gray-800 py-2 border-b px-4 border-gray-200 cursor-pointer hover:bg-gray-50"
        onClick={() => setCollapsed(c => !c)}
      >
        <div className="font-medium flex-1">
          {isNewMessage
            ? 'New Message'
            : participants
                .map(p => contacts.find(c => c.id === p.id)?.name)
                .join(', ')}
        </div>
        <span
          className="rounded-full p-1 -m-1 hover:bg-gray-200 cursor-pointer"
          onClick={() => onClose()}
        >
          <XIcon className="w-5 h-5" />
        </span>
      </div>
      {collapsed ? null : (
        <>
          {isNewMessage ? (
            <div
              className="flex-1 overflow-y-auto p-4 flex flex-col"
              ref={r => {
                if (r) {
                  r.scrollTop = r.scrollHeight;
                }
              }}
            >
              <div className="flex flex-shrink-0 items-center">
                <span className="font-medium text-gray-500 mr-2 text-sm">
                  To
                </span>
                <div className="border border-gray-200 rounded-md px-2 pt-2 pb-1 flex-1 text-sm flex flex-wrap leading-normal">
                  {selectedRecipients
                    .map(id => contacts.find(c => c.id === id))
                    .map(contact => (
                      <span className="text-gray-600 bg-gray-200 font-medium px-2 h-6 rounded mr-1 mb-1">
                        {contact?.name}
                      </span>
                    ))}
                  <input
                    className="inline-block flex-grow w-0 h-6 focus:outline-none mb-1"
                    type="text"
                    autoFocus
                    value={recipientFilterText}
                    onChange={e => setRecipientFilterText(e.target.value)}
                    onKeyUp={e => {
                      if (e.key === 'Backspace') {
                        setSelectedRecipients(r => r.slice(0, r.length - 1));
                      }
                    }}
                  />
                </div>
              </div>
              <div className="flex-1 overflow-y-auto -mx-4 px-4 mt-4 -mb-4">
                <div className="grid grid-cols-2 gap-4 pb-4">
                  {contacts
                    .filter(
                      c =>
                        !recipientFilterText ||
                        c.name
                          .toLowerCase()
                          .includes(recipientFilterText.toLowerCase()),
                    )
                    .map(contact => (
                      <div
                        key={contact.id}
                        className={classNames(
                          'rounded-md p-4 flex items-center flex-col cursor-pointer',
                          selectedRecipients.includes(contact.id)
                            ? 'bg-gray-200'
                            : 'bg-gray-50 hover:bg-gray-100',
                        )}
                        onClick={() => {
                          setSelectedRecipients(r =>
                            r
                              .filter(c => c !== contact.id)
                              .concat(
                                r.includes(contact.id) ? [] : [contact.id],
                              ),
                          );
                          setRecipientFilterText('');
                        }}
                      >
                        <div
                          className={classNames(
                            'rounded-full w-16 h-16 text-2xl font-medium text-white flex items-center justify-center',
                            contact.backgroundColor,
                          )}
                        >
                          <span>{contact.initials}</span>
                        </div>
                        <div className="font-medium text-sm text-gray-700 text-center leading-tight mt-2">
                          {contact.name}
                        </div>
                        <div className="text-xs text-gray-500 text-center leading-tight mt-2">
                          {contact.role}
                        </div>
                      </div>
                    ))}
                  <div className="group flex items-center flex-col justify-center cursor-pointer">
                    <div
                      className={classNames(
                        'rounded-full w-16 h-16 text-2xl font-medium text-white flex items-center justify-center bg-gray-200 group-hover:bg-gray-300',
                      )}
                    >
                      <PlusIcon className="h-16 w-16" />
                    </div>
                    <div className="font-medium text-sm text-gray-400 px-2 leading-tight text-center mt-2 group-hover:text-gray-500">
                      Add new contact
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="flex-1 flex flex-col overflow-y-auto p-4"
              ref={r => {
                if (r) {
                  r.scrollTop = r.scrollHeight;
                }
              }}
            >
              {messages.map(message => {
                const from = participants.find(p => p.id === message.from);
                const isFromSelf = !from;

                return (
                  <div
                    key={message.id}
                    className={classNames(
                      isFromSelf ? 'self-end' : 'self-start',
                      'mb-4 flex items-start',
                    )}
                    style={{ maxWidth: '80%' }}
                  >
                    {!isFromSelf ? (
                      <div className="bg-gray-400 text-white rounded-full w-7 h-7 font-bold flex items-center justify-center mr-2">
                        <span className="text-sm leading-none">JD</span>
                      </div>
                    ) : null}
                    <div>
                      <div
                        className={classNames(
                          isFromSelf
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-200 text-gray-800',
                          'py-1 px-2 rounded-md text-sm',
                        )}
                      >
                        {message.message}
                      </div>
                      <div
                        className={classNames(
                          'text-xs text-gray-400 mt-1',
                          isFromSelf ? 'text-right' : 'text-left',
                        )}
                      >
                        {moment(message.date).fromNow()}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div
            className={classNames(
              'flex items-center text-gray-800 py-3 border-t px-3 border-gray-200',
            )}
          >
            <div className="rounded-full mr-3 bg-blue-500 text-white cursor-pointer hover:bg-blue-600 p-1">
              <LightningBoltIcon className="w-4 h-4" />
            </div>
            <MentionsInput
              placeholder="Type a message..."
              className="MessagingInput"
              classNames={{
                MessagingInput: 'flex-1 text-gray-600 text-sm',
                MessagingInput__input: 'w-full focus:outline-none',
                MessagingInput__suggestions__list:
                  'mt-4 rounded shadow overflow-hidden bg-white',
                MessagingInput__suggestions__item: 'px-3 py-1',
                'MessagingInput__suggestions__item--focused': 'bg-gray-100',
              }}
              value={draftMessage}
              onChange={e => setDraftMessage(e.target.value)}
              allowSpaceInQuery
              inputRef={(r: HTMLInputElement) => {
                inputRef.current = r;
              }}
              onKeyDown={e => {
                // Enter = Send; Shift + Enter = Newline
                if (e.key === 'Enter' && !e.shiftKey) {
                  onSubmit();
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              <Mention trigger="@" appendSpaceOnAdd={false} data={[]} />
            </MentionsInput>
            <div className="ml-3 relative">
              <EmojiHappyIcon
                onClick={() => setShowEmojiPicker(p => !p)}
                className="w-6 h-6 text-gray-500 hover:text-gray-700 cursor-pointer"
              />
              {showEmojiPicker ? (
                <Picker
                  style={{
                    position: 'absolute',
                    bottom: 30,
                    right: 0,
                  }}
                  showPreview={false}
                  showSkinTones={false}
                  native={true}
                  onClick={emoji => {
                    setDraftMessage(m => m + (emoji as any).native);
                    setShowEmojiPicker(false);
                    inputRef.current?.focus();
                  }}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const MessagingContainer: React.FC<{}> = ({}) => {
  const { chats, composerVisible, hideComposer } = useMessaging();

  return (
    <>
      <div
        className="absolute bottom-0 flex gap-4 items-end pointer-events-none"
        style={{ right: 350 }} // right-6
      >
        {Object.values(chats).map(chat => (
          <MessagingPopup key={chat.id} chat={chat} onClose={() => {}} />
        ))}
        {composerVisible ? (
          <MessagingPopup onClose={() => hideComposer()} />
        ) : null}
      </div>
    </>
  );
};

export default MessagingContainer;
