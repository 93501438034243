import React from 'react';

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';

import config from '../config';

let previousToken: string;

export const AuthenticatedApolloProvider: React.FC = ({ children }) => {
  const authData = useAuth0();

  const getTokenSilently: () => Promise<string | undefined> = async () => {
    if (config.authentication) {
      const token = await authData.getAccessTokenSilently();
      return `user_${token}`;
    }

    const params = new URLSearchParams(window.location.search);
    const key = params.get('token');
    if (key) previousToken = key;
    return key || process.env.REACT_APP_API_KEY || previousToken;
  };

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_ENDPOINT,
    credentials: 'include',
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            adminClaims: {
              keyArgs: ['filter'],
              merge(existing = [], incoming, { args }) {
                if (args?.cursor) {
                  return [...existing, ...incoming];
                } else {
                  return incoming;
                }
              },
            },
          },
        },
      },
    }),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
