export type Config = {
  openWeatherMapApiKey: string;
  whitelabel: boolean;
  tenant: string;
  singleClaim?: boolean;
  useDemoIq?: boolean;
  useDemoIqFraud?: boolean;
  enableNpsSurvey?: boolean;
  enableMessaging?: boolean;
  enableIntercom?: boolean;
  enabledIntegrations?: 'policyLookup'[];
  enabledTabs?: string[];
  demoAuthentication?: boolean;
  authentication?: {
    domain: string;
    clientId: string;
    audience: string;
  };
  notifications?: {
    publicApiKey: string;
    feedId: string;
  };
  god?: boolean;
  hideUserManagement?: boolean;
  usePlatformMode?: boolean;
  useInvestigationMode?: boolean;
  useIntakeOnlyMode?: boolean;
  usePerspectiveViewInIntake?: boolean;
  usePerspectiveViewDemoInIntake?: boolean;
  intakeDisableAssignment?: boolean;
};

let config = {
  openWeatherMapApiKey: process.env.REACT_APP_OPEN_WEATHER_MAP_API_KEY!,
  whitelabel: false,
  tenant: 'Assured',
  singleClaim: false,
} as Config;

if (window.location.href.indexOf('safeauto-adjuster') !== -1) {
  config = { ...config, whitelabel: true, tenant: 'SafeAuto' };
}

if (window.location.href.includes('platform=1')) {
  config = { ...config, usePlatformMode: true };
}
if (window.location.href.includes('perspective_view_beta=1')) {
  config = { ...config, usePerspectiveViewInIntake: true };
}
if (window.location.href.includes('perspective_view_beta_demo=1')) {
  config = {
    ...config,
    usePerspectiveViewInIntake: true,
    usePerspectiveViewDemoInIntake: true,
  };
}

if (process.env.REACT_APP_SETTINGS) {
  config = { ...config, ...JSON.parse(process.env.REACT_APP_SETTINGS) };
}

export default config;
